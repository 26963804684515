@import url('https://fonts.googleapis.com/css?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cinzel+Decorative&display=swap');
@import 'assets/scss/_variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
// Global Styles

html {
  font-size: 12px;
}

body {
  font-family: "Raleway",Helvetica,Arial,sans-serif;
  font-size: 14px;
  height: 100%;
  color: #333;
  background: url('assets/img/statehouse.jpg') 50% fixed no-repeat;
  font-family: $font-family-sans-serif;
}


.container {
  position: relative;
}
.masthead {
  /*  background: url('assets/img/Senate Logo_White.png') no-repeat center left;
  background-size: 10%;*/
  color: #fff;
  display: block;
  font-family: "Cinzel",Georgia,"Times New Roman",Times,serif;
  opacity: .9;
  padding: 10px;
  margin-left:-15px;
  padding: 20px 20px 20px 0px;
  text-align: left;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  .headNumber {
    font-size: 1em;
  }

  .headCommonwealth {
    display: block;
    font-size: 1.4em;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
  color:$primary;
}
h1 {
  font-family: $font-family-serif;
  font-size: 3.3rem;
  margin: 1rem 0 1.2rem;
  color:$black;
}
h2 {
  font-size: 1.6rem;
}
h3 {
  font-size: 1.2rem;
}

.panel {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  -webkit-align-items: stretch;

  h2 {
    margin-top: 0.5rem;
    font-weight: bolder;
    font-size: 1.7rem;
  }
}

// Bootstrap Overrides
.btn {
  cursor: pointer;
  

  &-primary {
    background-color: $blue !important;
    border-color: $blue-dark !important;

    @include hover-state {
      background-color: $blue-dark !important;
    }
  }

  &-group {
    .btn-primary {
      background-color: $blue-lightest !important;
      color: $blue;

      @include hover-state {
        background-color: $blue-dark !important;
      }
    }
  }
}

.list-group-item {
  border: 0px;
  cursor: pointer;
}

.list-group-item.active {
  background-color: #D3D9E7;
  color: #000;
}

.nav-tabs .nav-link.active {
  background-color: #132450;
  color: #fff;
  z-index: 2;
}

.nav-tabs {
  background-color: #ffffff;
}

.nav-tabs .nav-item {
  margin-left: -1px;
  margin-right: 0px;
  padding: 0;
  color: #fff;
  width: 10.5%;
}

.nav-tabs .nav-link {
  border-radius: 15px 15px 0 0;
  background-color: #D3D9E7;
  border-color: #fff;
  position: relative;
  border: 0px;
  cursor: pointer;
  margin-bottom:1px;
}

.nav-tabs .nav-link::before {
  content: ''; /* To generate the box */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 70%;
  z-index: 1;
  border-bottom: none;
  margin-bottom:1px;
  border-radius: 0 15px 0 0;
  background: #D3D9E7;
  transform: scale(1.6, 1.62) perspective(0.5rem) rotateX( 7deg);
  transform-origin: bottom left;
  border-right: 1px solid #fff;
}

.nav-tabs .nav-link.active::before {
  content: ''; /* To generate the box */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 70%;
  z-index: 1;
  border-bottom: none;
  margin-bottom:1px;
  border-radius: 0 15px 0 0;
  background: #132450;
  transform: scale(1.6, 1.62) perspective(0.5rem) rotateX( 7deg);
  transform-origin: bottom left;
  border-right: 1px solid #fff;
}

.nav-tabs .nav-item:first-child {
    margin-left: 0px;
}

.mainNav {
  background: #132450;
}
// Alert
.alert-banner {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .alert-item {
    background-color: $yellow;

    a {
      text-decoration: none;
      cursor: pointer;
    }

    span {
      color: #000;
      font-weight: bold;
    }
  }
}

// Search
.search-icon {
  position: absolute;
  right: 35px;
}

// Header
header {
  background-color: $blue-dark;
  color: #fff;
  font-family: $font-family-serif;
}

.breadcrumb-container {
  background-color: $breadcrumb-bg;
}

// dashboard
.dashboard-button-container {
  gap: 20px 4%;
}

.dashboard-button {
  border-radius: 10px;
  border: 0;
  flex: 48%;
  font-size:1.1rem;

  img {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    border-radius: 0 0 10px 10px;
    flex: 1 1 auto;
    color: #fff;
  }

  .svg-inline--fa {
    display: block;
    font-size: 2.5em;
    margin: 0 auto 10px;
  }
}

.dashboard-video-button {
  font-size:1.1rem;
}

// quick links
.quick-links {
  margin-bottom: 9rem;
  .quick-link:last-child a {
      margin-bottom:0;
  }
  .btn {
    margin-bottom: 1rem;
    line-height: 1.3rem;

    img {
      width: 10%;
      object-fit: contain;
      filter: brightness(0) invert(1);
    }
    .btn-title {
        font-weight:700;
    }
    .btn-description {
        font-size:1.1rem;
    }
  }
}

// events
.events .list-group-item {
  padding: 0;
  text-align: left;
  display:flex;
  flex-direction:column;
  justify-content:center;

  span {
    display: block;
  }

  div {
    text-align: left;
  }

  &:nth-child(odd) {
    background-color: $blue;

    div {
      color: $white;
    }
  }
  .btn {
  }
}

.events .list-group {
  max-height: 372.84px;
  overflow: auto;
}

.events .list-group {
  max-height: 372.84px;
  overflow: auto;
}

// contact
.contact {
  display: flex;
  cursor: pointer;

  .contact-initial {
    display: flex;
    background: black;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    color: #fff;
    align-content: center;
    justify-content: center;
    line-height: 2.4rem;
    margin-right: 8px;
  }

  .contact-icon {
    display: flex;
    background: #fff;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    color: #fff;
    align-content: center;
    justify-content: center;
    object-fit: cover;
    margin-right: 8px;
  }
  .contact-name, .contact-role {
    font-size: 1rem;
    line-height: 1.2rem;
    display: block;
  }
}

// shared
.link-button {
  border-radius: 42px;
  margin-top:.15rem;
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
  .link-content {
    display: flex;
    align-items: flex-start;
    text-align: left;
    img {
      width: 22px;
      height: 22px;
      font-size: 22px;
      line-height: 22px;
      flex: 0 0 auto;
      margin: 0px 12px;
      align-self: center;
      -webkit-filter: brightness(0) invert(1);
    }
    span {
        font-size:1.3rem;
        color: #fff;
    }
  }
}

// content
.plain-text {
  white-space: break-spaces;
}

// Document Hub
.document-hub-btn {
  border-radius: 42px;
}
.document-table label, .directory-level {
  cursor: pointer;
}
.directory-container {
    margin-left:-1rem;
}
.directory:hover {
    background:$gray-300;
}
.subpage {
  padding-bottom: 75px;
}

.nav-bottom {
  height: 8px;
  background-color: #132450;
}

// Footer
.footer {
  background-color: #132450;
  color: #fff;

  a {
    color: white;
    text-decoration:underline;
  }
}

// Employee Benefit
.benefits-panel {
  overflow: auto;

  h2 {
    font-size: 2rem;
  }

  h3 {
    margin-top:.5rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
  }

}

// Emergency Services
.emergency-contact {
  font-size: 24px;
  background-color: red;
  color: #fff;
  border-radius: 15px;
}

.emergency-card {
  height: 100%;

  img {
    width: 20%;
  }

  ul {
    padding-left: 13px;

    li {
      margin-bottom: 5px;
    }
  }

  .title {
    text-align: center;
    font-weight: bold;
    color: #254387;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  h2 {
    font-size: 1.4rem;
  }

  .masthead {
    background-size: 17%;
    display: inline-block;
    font-size: 1.1em;
    margin: 0;
  }

  header {
    background-color: #132450;
  }

  .quick-links .btn {
    img, fa-icon {
      width: 12%;
    }
  }

  .dashboard-button {
    flex: 100%;
    span {
        font-weight:700;
    }
  }

  .contact-container {
    margin-bottom: 9rem;
  }
}
@media (min-width: 992px) {
  .masthead {
    font-size: 1.4em;
  }
  .subpage .dashboard-button-container img {
    height: 240px;
  }
  .subpage .dashboard-button-container .home-dashboard-button img {
    height: auto;
  }
  .dashboard-button {
    -webkit-box-flex: 1;
    flex: 1;

    span {
      font-size: 1.3rem;
    }
  }
}
.home-dashboard-button:first-child {
  flex: 3 1 auto;
}

// Water Mark
@media (min-width:768px) {
  .watermark.d-flex.align-items-center.justify-content-center {
    height: 79%;
  }

  .watermark div {
    margin-top: 0rem;
  }
}

@media (min-width:992px) {
  .watermark.d-flex.align-items-center.justify-content-center {
    height: 62%;
  }
}
.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  left: 50%;
  text-align: center;
  background-color: rgba(255,255,255,0.7);
  transform: translate(-50%, 0);

  div {
    font-size: 82px;
    font-weight: 700;
    margin-top: -10rem;
    color:$blue-dark;
  }
}
.benefits-panel {
  min-height: 520px;
}
